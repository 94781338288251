import { getItem } from '@/utils/storage'

// export type Response<T> = {
//     statusCode: number
//     data: T
// }
export type Response<T> = T

export const getHeaders = () => {
    return {
        'x-access-token': getItem(localStorage, 'token'),
        'x-sistema': 'SATISFACIL',
        'x-versao': import.meta.env.VITE_VERSION,
    }
}

export const getData = <T>(response: T) => {
    return response
}
