import { ImmerStateCreator } from '@/store/type'
import {
    FormSlice,
    FormState,
    FieldType,
    SectionState,
    FieldState,
} from '@/store/formSlice/type'
import { FieldType as APIFieldType } from '@/api/business/form/types'

const initialState: FormState = {
    initialFormState: {},
    formState: {},
    user: {
        isValid: false,
        userRegistration: '',
    },
    shouldValidate: false,
}

const fieldType: Record<APIFieldType, FieldType> = {
    checkbox: FieldType.options,
    radio: FieldType.options,
    text: FieldType.value,
    number: FieldType.value,
}

export const useFormSlice: ImmerStateCreator<FormSlice> = (set) => ({
    state: initialState,
    actions: {
        setInitialValues: (form) => {
            const newFormState: SectionState = {}

            for (const section of form) {
                const fieldState: FieldState = {}

                for (const field of section.fields) {
                    fieldState[field.id] = {
                        fieldId: field.id,
                        value: undefined,
                        isRequired: field.isRequired,
                        type: fieldType[field.type],
                    }
                }

                newFormState[section.id] = {
                    sectionId: section.id,
                    fieldState,
                }
            }

            set((state) => {
                state.formSlice.state.formState = newFormState
                state.formSlice.state.initialFormState = newFormState
            })
        },
        setFieldValue: (sectionId, fieldId, value) => {
            set((state) => {
                const section = state.formSlice.state.formState[sectionId]

                if (!section) {
                    console.error(`Seção com ID ${sectionId} não encontrada.`)
                    return
                }

                const field = section.fieldState[fieldId]

                if (!field) {
                    console.error(
                        `Campo com ID ${fieldId} não encontrado na seção ${sectionId}.`
                    )
                    return
                }

                section.fieldState[fieldId] = {
                    ...field,
                    ...value,
                }
            })
        },
        setShouldValidate: (shouldValidate) => {
            set((state) => {
                state.formSlice.state.shouldValidate = shouldValidate
            })
        },
        setUser: (user) => {
            set((state) => {
                state.formSlice.state.user.userRegistration = user
            })
        },
        setIsUserValid: (isValid) => {
            set((state) => {
                state.formSlice.state.user.isValid = isValid
            })
        },
        onReset: () =>
            set((state) => {
                console.log('reset')
                state.formSlice.state.formState =
                    state.formSlice.state.initialFormState

                state.formSlice.state.user = initialState.user
                state.formSlice.state.shouldValidate =
                    initialState.shouldValidate
            }),
    },
})
