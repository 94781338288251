import { answerForm } from '@/api/business/form'
import { Button } from '@/components/ui/button'
import { useBaseStore } from '@/store'
import { FieldType } from '@/store/formSlice/type'
import { Store } from '@/store/type'
import { getError } from '@/utils/getError'
import { useMutation } from '@tanstack/react-query'
import { useMemo } from 'react'
import { toast } from 'sonner'

const stateSelector = (state: Store) => ({
    formState: state.formSlice.state.formState,
    user: state.formSlice.state.user,
    setShouldValidate: state.formSlice.actions.setShouldValidate,
    onReset: state.formSlice.actions.onReset,
})

const FormFooter = () => {
    const { formState, user, onReset, setShouldValidate } =
        useBaseStore(stateSelector)

    const { mutate, isPending } = useMutation({
        mutationFn: answerForm,
        onSuccess: () => {
            const formContainer = document.querySelector('[data-form]')
            formContainer?.scrollTo({ top: 0 })

            toast.success('Respostas enviadas com sucesso')
            onReset()
        },
        onError: (err) => {
            const message = getError(err, 'Erro ao responder formulário')

            toast.error(message)
        },
    })

    const answers = useMemo(() => {
        return Object.values(formState).map((sectionState) => {
            return {
                ...sectionState,
                fieldState: Object.values(sectionState.fieldState),
            }
        })
    }, [formState])

    const onSubmit = () => {
        setShouldValidate(false)

        if (!user.isValid)
            return toast.error(
                'Colaborador inválido. Insira uma matrícula válida'
            )

        const hasError =
            answers.filter((section) =>
                section.fieldState.some(
                    (field) =>
                        field.isRequired &&
                        (!field.value ||
                            (Array.isArray(field.value) &&
                                field.value.length === 0))
                )
            ).length > 0

        if (hasError) {
            setShouldValidate(true)
            return toast.error('Preencha todos os campos')
        }

        const response = answers.flatMap((answer) => {
            return answer.fieldState
                .filter((field) => !!field.value)
                .map((field) => {
                    if (field.type === FieldType.options) {
                        return {
                            type: 'optionResponse' as const,
                            sectionId: answer.sectionId,
                            fieldId: field.fieldId,
                            value: null,
                            options: (field?.value as number[])?.map(
                                (option) => ({
                                    optionId: option,
                                })
                            ),
                        }
                    }

                    return {
                        type: 'response' as const,
                        sectionId: answer.sectionId,
                        fieldId: field.fieldId,
                        value: field.value as string,
                    }
                })
        })

        console.log(response)

        mutate({
            userRegistration: user.userRegistration,
            response,
        })
    }

    return (
        <div>
            <div className="flex justify-end py-4">
                <Button
                    className="shadow-md"
                    onClick={onSubmit}
                    disabled={isPending}
                >
                    Enviar respostas
                </Button>
            </div>
            <div className="p-4" />
        </div>
    )
}

export default FormFooter
