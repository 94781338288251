import Container from '@/components/Container'
import { useEffect } from 'react'
import { useFormQuery } from '@/queries/useFormQuery'
import FormSection from '@/pages/Home/components/FormSection'
import { FormSectionContextProvider } from '@/pages/Home/context/FormSectionContext'
import { Store } from '@/store/type'
import { useBaseStore } from '@/store'
import UserRegistrationSection from '@/pages/Home/components/UserRegistrationSection'
import FormFooter from '@/pages/Home/components/FormFooter'
import PageLoader from '@/pages/PageLoader'
import { Skeleton } from '@/components/ui/skeleton'

const stateSelector = (state: Store) => ({
    setInitialValues: state.formSlice.actions.setInitialValues,
})

const FormData = () => {
    const { setInitialValues } = useBaseStore(stateSelector)
    const { data = [] } = useFormQuery({ enabled: true })

    useEffect(() => {
        if (data.length > 0) setInitialValues(data)
    }, [data])

    return (
        <>
            <UserRegistrationSection />
            <div className="space-y-8">
                {data.map((section) => {
                    return (
                        <FormSectionContextProvider
                            key={section.id}
                            sectionId={section.id}
                        >
                            <FormSection section={section} />
                        </FormSectionContextProvider>
                    )
                })}
            </div>
            <FormFooter />
        </>
    )
}

const FormSkeleton = () => {
    return (
        <div className="space-y-4">
            <Skeleton className="h-[120px] w-full" />
            {[1, 2, 3].map((section) => {
                return <Skeleton className="h-[340px] w-full" key={section} />
            })}
            <div className="p-4" />
        </div>
    )
}

const Form = () => {
    const { isPending, error } = useFormQuery({ enabled: true })

    return (
        <Container
            data-form
            className="relative p-4 space-y-4 md:max-w-[640px]"
        >
            <PageLoader
                isLoading={isPending}
                error={error}
                fallback={<FormSkeleton />}
            >
                <FormData />
            </PageLoader>
        </Container>
    )
}

export default Form
