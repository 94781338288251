import { FormField } from '@/api/business/form/types'
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from '@/components/ui/card'
import { Label } from '@/components/ui/label'
import { useFormSectionContext } from '../../context/FormSectionContext'
import { useEffect, useMemo, useState } from 'react'
import { FieldType } from '@/store/formSlice/type'
import { cn } from '@/lib/utils'
import { Checkbox } from '@/components/ui/checkbox'

type FieldCheckboxProps = {
    formField: FormField
}

const FieldCheckbox = ({ formField }: FieldCheckboxProps) => {
    const { sectionId, shouldValidate, sectionFieldState, setFieldValue } =
        useFormSectionContext()

    const value = useMemo(
        () => sectionFieldState && sectionFieldState[formField.id],
        [sectionFieldState, formField]
    )

    const [selectedRadio, setSelectedRadio] = useState<Record<string, boolean>>(
        (value?.value as number[])?.reduce((acc, curr) => {
            acc[curr.toString()] = true

            return acc
        }, {}) || {}
    )

    let isValid = true

    if (value?.isRequired) {
        isValid = (value?.value as number[])?.length > 0
    }

    const isError = shouldValidate && !isValid

    useEffect(() => {
        if (!value?.value) {
            setSelectedRadio({})
        }
    }, [value])

    const onChange = (value: string, isChecked: boolean) => {
        setSelectedRadio((prev) => {
            const newValue = {
                ...prev,
                [value]: isChecked,
            }

            setFieldValue(sectionId, formField.id, {
                type: FieldType.options,
                value: Object.entries(newValue)
                    .filter(([, value]) => value)
                    .map(([id]) => Number(id)),
            })

            return newValue
        })
    }

    return (
        <Card
            className={cn(
                'border-white shadow-lg',
                isError && 'border-destructive'
            )}
        >
            <CardHeader className="space-y-0 border-b">
                <CardTitle
                    className={cn(
                        'flex items-center gap-2 text-md',
                        isError ? 'text-destructive' : ''
                    )}
                >
                    {formField.label}
                    {formField.isRequired && (
                        <span className="text-red-500">*</span>
                    )}
                </CardTitle>
                <CardDescription>Selecione uma ou mais opção</CardDescription>
            </CardHeader>
            <CardContent className="p-4">
                <div>
                    {formField.options.map((option) => {
                        const isSelected = selectedRadio[option.id.toString()]

                        return (
                            <div
                                key={`${sectionId}${formField.id}${option.id}`}
                                className={cn(
                                    'flex items-center gap-2 rounded-lg px-4 cursor-pointer'
                                )}
                            >
                                <Checkbox
                                    id={option.id.toString()}
                                    value={option.id.toString()}
                                    checked={isSelected}
                                    onCheckedChange={(e) =>
                                        onChange(option.id.toString(), !!e)
                                    }
                                />
                                <Label
                                    htmlFor={option.id.toString()}
                                    className={cn('flex-1 py-4')}
                                >
                                    {option.label}
                                </Label>
                            </div>
                        )
                    })}
                </div>
            </CardContent>
        </Card>
    )
}

export default FieldCheckbox
