import {
    Card,
    CardDescription,
    CardHeader,
    CardTitle,
} from '@/components/ui/card'
import { FormSection as FormSectionType } from '@/api/business/form/types'
import { Crown } from 'lucide-react'
import FormField from '../FormField'

type FormSectionProps = {
    section: FormSectionType
}

const FormSection = ({ section }: FormSectionProps) => {
    return (
        <div className="space-y-2">
            <Card
                key={section.id}
                className="relative overflow-hidden min-h-[100px] border-white shadow-lg"
            >
                <CardHeader className="pl-10 space-y-0">
                    <CardTitle className="z-10 text-xl text-primary">
                        {section.title}
                    </CardTitle>
                    {section.description && (
                        <CardDescription className="z-10">
                            {section.description}
                        </CardDescription>
                    )}
                    <Crown
                        size={60}
                        className="absolute z-0 -translate-y-1/2 -left-6 top-1/2 text-primary/50"
                    />
                </CardHeader>
            </Card>
            {section.fields.map((field) => {
                return <FormField key={field.id} formField={field} />
            })}
        </div>
    )
}

export default FormSection
