import { FormField } from '@/api/business/form/types'
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from '@/components/ui/card'
import { Textarea } from '@/components/ui/textarea'
import { useFormSectionContext } from '../../context/FormSectionContext'
import { useEffect, useMemo, useState } from 'react'
import { FieldType } from '@/store/formSlice/type'
import { cn } from '@/lib/utils'

type FieldTextProps = {
    formField: FormField
}

const FieldText = ({ formField }: FieldTextProps) => {
    const { sectionId, shouldValidate, sectionFieldState, setFieldValue } =
        useFormSectionContext()

    const value = useMemo(
        () => (sectionFieldState ? sectionFieldState[formField.id] : undefined),
        [sectionFieldState, formField]
    )

    const [inputValue, setInputValue] = useState((value?.value as string) || '')

    let isValid = true

    if (value?.isRequired) {
        isValid = (value.value as string).trim() !== ''
    }

    const isError = shouldValidate && !isValid

    useEffect(() => {
        setInputValue((value?.value as string) || '')
    }, [value])

    return (
        <Card className="border-white shadow-lg">
            <CardHeader className="space-y-0 border-b">
                <CardTitle
                    className={cn(
                        'flex items-center gap-2 text-md',
                        isError && 'text-destructive'
                    )}
                >
                    {formField.isRequired && (
                        <span className="text-red-500">*</span>
                    )}
                    {formField.label}
                </CardTitle>
                <CardDescription>{formField.placeholder}</CardDescription>
            </CardHeader>
            <CardContent className="p-4">
                <Textarea
                    className={cn(
                        'resize-none border-primary/60',
                        isError && 'text-destructive'
                    )}
                    placeholder="Digite aqui..."
                    value={inputValue}
                    onChange={(e) => {
                        setInputValue(e.target.value)
                        setFieldValue(sectionId, formField.id, {
                            type: FieldType.value,
                            value: e.target.value,
                        })
                    }}
                />
            </CardContent>
        </Card>
    )
}

export default FieldText
