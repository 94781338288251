import { TypographyP } from '@/components/ui/typography'
import { DebouncedInput } from '@/components/DebouncedInput'
import { Label } from '@/components/ui/label'
import { useUserQuery } from '@/queries/useUserQuery'
import { cn } from '@/lib/utils'
import { Store } from '@/store/type'
import { useBaseStore } from '@/store'
import { useEffect } from 'react'

const stateSelector = (state: Store) => ({
    user: state.formSlice.state.user,
    shouldValidate: state.formSlice.state.shouldValidate,
    setUser: state.formSlice.actions.setUser,
    setIsUserValid: state.formSlice.actions.setIsUserValid,
})

const UserRegistrationSection = () => {
    const {
        user: userState,
        shouldValidate,
        setUser,
        setIsUserValid,
    } = useBaseStore(stateSelector)

    const { isSuccess, isLoading } = useUserQuery({
        enabled: !!userState.userRegistration,
        userRegistration: userState.userRegistration,
    })

    const isValid = !shouldValidate || userState.isValid

    useEffect(() => {
        if (!isLoading) setIsUserValid(isSuccess)
    }, [isSuccess, isLoading])

    return (
        <div className="flex flex-col gap-2 p-4 rounded-lg shadow-lg itemscenter bg-card">
            <TypographyP asChild>
                <Label
                    className={cn(
                        'font-semibold text-foreground',
                        !isValid && 'text-destructive'
                    )}
                >
                    Matrícula
                </Label>
            </TypographyP>
            <DebouncedInput
                className={cn(
                    'w-full p-2 rounded-md border-2 bg-transparent',
                    isLoading && 'animated-border',
                    !isValid && 'border-destructive'
                )}
                type='number'
                placeholder="Insira sua matrícula"
                value={userState.userRegistration}
                onChange={(value) => setUser(value as string)}
                debounce={800}
            />
        </div>
    )
}

export default UserRegistrationSection
