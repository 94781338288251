import { FormSection } from '@/api/business/form/types'
import { Slice } from '../type'

export enum FieldType {
    options = 'options',
    value = 'value',
}

type FieldStateCommonProps = {
    isRequired: boolean
    fieldId: number
}

export type FieldValue =
    | {
          type: FieldType.options
          value: number | number[] | undefined
      }
    | {
          type: FieldType.value
          value: string | number | undefined
      }

export type FieldState = Record<number, FieldValue & FieldStateCommonProps>
export type SectionState = Record<
    number,
    { sectionId: number; fieldState: FieldState }
>

export type User = {
    userRegistration: string
    isValid: boolean
}

export type FormState = {
    formState: SectionState
    initialFormState: SectionState
    shouldValidate: boolean
    user: User
}

export type SetFieldValueFn = (
    sectionId: number,
    fieldId: number,
    value: FieldValue
) => void

export type FormActions = {
    setInitialValues: (form: FormSection[]) => void
    setFieldValue: SetFieldValueFn
    setShouldValidate: (shouldValidate: boolean) => void
    setUser: (userRegistration: string) => void
    setIsUserValid: (isValid: boolean) => void
}

export type FormSlice = Slice<FormState, FormActions>
